import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatNumber, formatUSDT } from '@shared/lib/numbers';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyLargeSemiBold, BodyMedium, BodyMediumSemiBold, BodySmall, BodySmallSemiBold, BodyLargeBold, } from '@components/styled/Typography';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import Button from '@shared/ui/buttons/Button';
import { AREAS_COLORS } from '@entities/wallet';
import PortfolioBalanceUnit from './PortfolioBalanceUnit';
const ExchangePortfoliosContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'isExchangeSupported' })(props => ({
    width: 'auto',
    height: 'auto',
    minHeight: 150,
    backgroundColor: '#F8F9FA',
    borderRadius: 6,
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.l}px`,
    boxSizing: 'border-box',
    justifyContent: props.isExchangeSupported ? 'space-between' : 'center',
}));
const ExchangePortfoliosCount = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    lineHeight: '24px',
    opacity: props.disabled ? 0.5 : 1,
}));
const PortfolioBalancesList = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.m,
    height: 'auto',
    flexWrap: 'wrap',
    marginBottom: 12,
}));
const PortfolioBalanceContainer = styled.div();
const PortfolioTotalBalance = styled(ContainerRow)(props => ({
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: props.theme.spacing_sizes.s,
}));
const PortfolioBalanceTitle = styled(BodyMedium, { shouldForwardProp: propName => propName !== 'disabled' })((props) => ({
    lineHeight: '22px',
    opacity: props.disabled ? 0.5 : 1,
}));
const PortfolioBalanceSum = styled(BodyLargeSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    lineHeight: '22px',
    opacity: props.disabled ? 0.5 : 1,
}));
const BalanceInterval = styled(ContainerRow)(() => ({
    height: 20,
    justifyContent: 'space-between',
}));
const BalanceIntervalPeriod = styled(BodySmallSemiBold)(props => ({
    color: props.theme.palette.text.secondary,
}));
const BalanceIntervalIncome = styled(ContainerRow)(props => ({
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const IntervalIncomeAmount = styled(BodySmallSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    color: props.theme.palette.success.main,
    lineHeight: '15px',
    opacity: props.disabled ? 0.5 : 1,
}));
const IncomeBadgeLarge = styled(withPercentageIncomeBadge(BodySmall), { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    fontWeight: '600',
    opacity: props.disabled ? 0.5 : 1,
}));
const ArrowIconStyled = styled(ArrowIcon)(() => ({
    transform: 'rotate(180deg)',
}));
const ButtonStyled = styled(Button)(() => ({
    padding: 0,
    minHeight: 'unset',
    border: 'unset',
}));
const UnsupportedTitle = styled(BodyLargeBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    marginBottom: props.theme.spacing_sizes.s,
    opacity: props.disabled ? 0.5 : 1,
}));
const UnsupportedText = styled(BodyMedium, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    fontSize: 14,
    lineHeight: '22px',
    opacity: props.disabled ? 0.5 : 1,
}));
const UnsupportedInner = ({ disabled = undefined }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(UnsupportedTitle, { disabled: disabled, children: t('overview.exchange_wallets.card.unsupported_wallet_title') }), _jsx(UnsupportedText, { disabled: disabled, children: t('overview.exchange_wallets.card.unsupported_wallet_text') })] }));
};
const ExchangePortfolios = ({ exchangeId, portfolios, dailyIncome = 0, dailyIcomeRatio = 0, isExchangeSupported, disabled = undefined, className = undefined, }) => {
    const { t } = useTranslation();
    const { portfoliosList, totalAmount, otherCount, } = useMemo(() => portfolios
        .reduce((acc, portfolio) => {
        var _a;
        if (exchangeId === ((_a = portfolio.exchange) === null || _a === void 0 ? void 0 : _a.id)) {
            if (acc.portfoliosList.length === 6) {
                acc.otherCount += portfolio.totalAssetsAmount || 0;
                acc.totalAmount += portfolio.totalAssetsAmount || 0;
            }
            else {
                acc.portfoliosList.push(portfolio);
                acc.totalAmount += portfolio.totalAssetsAmount || 0;
            }
        }
        return acc;
    }, {
        portfoliosList: [],
        totalAmount: 0,
        otherCount: 0,
    }), [[portfolios]]);
    if (isExchangeSupported === false) {
        return (_jsx(ExchangePortfoliosContainer, { isExchangeSupported: isExchangeSupported, children: _jsx(UnsupportedInner, { disabled: disabled }) }));
    }
    return (_jsxs(ExchangePortfoliosContainer, { className: className, isExchangeSupported: isExchangeSupported, children: [_jsx(ExchangePortfoliosCount, { disabled: disabled, children: `${t('overview.exchange_wallets.card.total_portfolios')} ${portfoliosList.length}` }), (portfoliosList === null || portfoliosList === void 0 ? void 0 : portfoliosList.length) > 0 ? (_jsxs(PortfolioBalancesList, { children: [portfoliosList.map((portfolio, index) => (_jsx(PortfolioBalanceUnit, { color: AREAS_COLORS[index], amount: formatNumber(portfolio.totalAssetsAmount || 0), disabled: disabled, currency: '$' }, portfolio.id))), otherCount !== 0 && (_jsx(PortfolioBalanceUnit, { color: AREAS_COLORS[portfoliosList.length] || 'transparent', amount: formatNumber(otherCount), disabled: disabled, currency: '$' }, 'others'))] })) : (_jsx(ButtonStyled, { endIcon: ArrowIconStyled, variant: 'plain', disabled: disabled, children: t('overview.exchange_wallets.card.explore_marketplace') })), portfoliosList.length > 0 ? (_jsxs(PortfolioBalanceContainer, { children: [_jsxs(PortfolioTotalBalance, { children: [_jsx(PortfolioBalanceTitle, { disabled: disabled, children: t('overview.exchange_wallets.card.balance') }), _jsx(PortfolioBalanceSum, { disabled: disabled, children: formatUSDT(totalAmount) })] }), _jsxs(BalanceInterval, { children: [_jsx(BalanceIntervalPeriod, { children: "24 H" }), _jsxs(BalanceIntervalIncome, { children: [_jsx(IntervalIncomeAmount, { disabled: disabled, children: formatUSDT(dailyIncome) }), _jsx(IncomeBadgeLarge, { badgeVariant: 'tinted', badgeSize: 'small', percentageIncome: dailyIcomeRatio, disabled: disabled })] })] })] })) : null] }));
};
export default ExchangePortfolios;
